import { Section } from "@components/UI-Elements/General";
import Title from "@components/UI-Elements/Typography/Title";
import React from "react";
import { bitcoinsBooks } from "../../common/data/content/bitcoin-books";
import { newsPages } from "../../common/data/content/news-pages";
import { formatLanguage } from "../../common/helpers/formatter";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Card from "../../components/UI-Elements/Card";
import LinkList from "../../components/UI-Elements/Lists/LinkList";
import Tag from "../../components/UI-Elements/Tag/Tag";

const books = bitcoinsBooks.map((book) => ({
  href: book.href,
  title: (
    <div className="py-1">
      <span className="font-bold">{book.title}</span>
      <br />
      Author: {book.author}
    </div>
  ),
  external: true,
}));

const news = newsPages.map((page) => ({
  href: page.href,
  title: (
    <div className="py-1">
      <span className="font-bold">{page.title} </span>
      <Tag>{formatLanguage(page.language)}</Tag>
      {page.tags.map((tag) => (
        <Tag key={tag} title={tag} />
      ))}
    </div>
  ),
  external: true,
}));

const WissenIndex: React.FC = () => {
  return (
    <Layout>
      <SEO keywords={["newsseiten", "blogs", "bitcoin bücher"]} title="Informationsquellen" />
      <Title>Informationsquellen</Title>
      <div className="grid">
        <Section>
          <div>
            <Title level={2}>📄 Newsseiten & Blogs</Title>
          </div>
          <Card prose={false}>
            <p className="pb-2">Aktuelle News, Magazine über Ereignisse und Geschehnisse aus der Welt der Kryptowährungen.</p>
            <LinkList links={news} />
          </Card>
        </Section>
        {/* <Section">
          <div>
            <Title level={2}>📄 Blogs</H2>
          </div>
          <Card>
            <LinkList links={pages.map((page) => ({ ...page, external: true }))} />
          </Card>
        </Section> */}
      </div>

      <Section>
        <div>
          <Title level={2}>📚 Bücher</Title>
        </div>
        <Card prose={false}>
          <LinkList links={books} />
        </Card>
      </Section>
    </Layout>
  );
};

export default WissenIndex;
// https://bitcoin-live.de/ --> news aggregator
// https://cryptocurrency-tax.info/

// Personen


// Bitcoin
// https://safehodl.github.io/failure/
// https://endthefud.org/
// https://coin-update.de/
// https://dailyhodl.com/

// https://bitcointalk.org/index.php?topic=5289449.20
// https://bitcointalk.org/index.php?topic=5299615.40

// Forum
// https://coinforum.de/
// https://bitcointalk.org/index.php?topic=5289449.20
// https://forum.blocktrainer.de/t/texte-und-videosammlung-fuer-neulinge-read-only/12658
// https://www.btc-forum.de/

// Bücher
// https://www.bitcoinerbooks.com/
// https://bitcoin-resources.com/books/
// https://www.jordanbpeterson.com/great-books/


// https://stepn.com/getCode?id=16467124561514
// https://stepn.com/getCode?id=16467124561514


// Namen
// elcoinzo


// https://ccc.io/#
// https://stepn.com
// https://research.binance.com/en/analysis/russia-&-ukraine-what-does-it-mean-for-cryptocurrency
// https://www.coinfairvalue.com/
// https://research.binance.com/en/analysis/russia-&-ukraine-what-does-it-mean-for-cryptocurrency