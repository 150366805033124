export type NewsPage = {
  href: string;
  title: string;
  tags?: string[];
  language?: string;
};

export const newsPages: NewsPage[] = [
  {
    href: "https://www.btc-echo.de/",
    title: "BTC-Echo",
    tags: ['news'],
    language: "german",
  },
  {
    href: "https://de.cointelegraph.com/",
    title: "Cointelegraph",
    tags: ['news'],
    language: "german",
  },
  {
    href: "https://bitcoin-2go.de/",
    title: "Bitcoin2Go",
    tags: ['news'],
    language: "german",
  },
  {
    href: "https://de.beincrypto.com",
    title: "BeInCrypto",
    tags: ['news'],
    language: "german",
  },
  {
    href: "https://de.cryptonews.com/",
    title: "Cryptonews",
    tags: ['news'],
    language: "german",
  },
  // {
  //     link: 'https://coin-ratgeber.de/',
  //     title: '',
  //     tags: ['news'],
  //     language: 'german'
  // },
  {
    href: "https://blockchainwelt.de/",
    title: "Blockchainwelt",
    tags: ['news'],
    language: "german",
  },
  {
    href: "https://bitcoinblog.de/",
    title: "BitcoinBlog",
    tags: ['news'],
    language: "german",
  },
  {
    href: "https://coin-update.de/",
    title: "Coin-Update",
    tags: ['news'],
    language: "german",
  },
  {
    href: "https://coincierge.de/",
    title: "Coincierge",
    tags: ['news'],
    language: "german",
  },

  {
    href: "https://bitcoinmagazine.com/",
    title: "Bitcoin Magazine",
    tags: ['news'],
    language: "english",
  },
  {
    href: "https://www.theblockcrypto.com/",
    title: "The Block",
    tags: ['news'],
    language: "english",
  },
  {
    href: "https://decrypt.co/",
    title: "Decrypt",
    tags: ['news'],
    language: "english",
  },
  {
    href: "https://www.coindesk.com/",
    title: "Coindesk",
    tags: ['news'],
    language: "english",
  },
  {
    href: "https://cryptoslate.com/",
    title: "Cryptoslate",
    tags: ['news'],
    language: "english",
  },
  {
    href: "https://bitcoinist.com/",
    title: "Bitcoinist",
    tags: ['news'],
    language: "english",
  },
  {
    href: "https://www.blocktrainer.de/",
    title: "Blocktrainer",
    language: "german",
    tags: ['blog'],
  },
  {
    href: "https://www.blockchaincenter.net",
    title: "blockchaincenter.net",
    language: "german",
    tags: ['blog'],
  },
  {
    href: "https://coin-ratgeber.de",
    title: "coin-ratgeber.de",
    language: "german",
    tags: ['blog'],
  },
];
